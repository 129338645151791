import Swiper, { Navigation, Pagination, Lazy, Autoplay } from 'swiper';
import InfinityScroll from '../components/infinityScroll';
import setCarousel from '../components/carousel';
import { updatePriceBlock, handleLoadVideos } from '../components/utilities';

import AOS from 'aos';
import { setYoutube } from '../components/vndaComponents';

Swiper.use([Navigation, Pagination, Lazy, Autoplay]);

const Home = {
  setFullbanner: () => {
    const fullbanners = document.querySelectorAll('[data-fullbanner]');

    let resolution = 'desktop';
    if (window.innerWidth > 1024) resolution = 'mobile';

    // Remove o fullbanner que não é da resolução do dispositivo
    fullbanners.forEach((fullbanner) => {
      if (fullbanner.classList.contains(`-${resolution}`)) {
        fullbanner.parentElement.removeChild(fullbanner);
      }
    });

    fullbanners.forEach((section, swiperIndex) => {
      const carousel = section.querySelector('.swiper');
      const pagination = section.querySelector('.swiper-pagination');
      const next = section.querySelector('.swiper-button-next');
      const prev = section.querySelector('.swiper-button-prev');

      const newSwiper = new Swiper(carousel, {
        slidesPerView: 1,
        spaceBetween: 1,
        speed: 1000,
        autoHeight: true,
        autoplay: {
          delay: 5000,
        },
        watchOverflow: true,
        preloadImages: false,
        lazy: {
          checkInView: true,
          loadPrevNext: true,
          loadOnTransitionStart: true,
        }
      });
      newSwiper.autoplay.stop();
      const setAutoPlay = () => {
        window.removeEventListener('scroll', setAutoPlay);
        window.removeEventListener('mousemove', setAutoPlay);
        newSwiper.autoplay.start();
        console.log('Autoplay!')
      }
      window.addEventListener('scroll', setAutoPlay);
      window.addEventListener('mousemove', setAutoPlay);
    });
  },
  setYoutubeCode: function() {
    const setYoutube = () => {
      if(!window._video_src) return;

      window.removeEventListener('mousemove', setYoutube);
      window.removeEventListener('scroll', setYoutube);
      
      let htmlTag = document.querySelector('[data-video-fullbanner]');
      let source = window._video_src;
      htmlTag.setAttribute('src', source);
      window._video_src = null;
    }

    window.addEventListener('load', () => {
      setTimeout(() => {
        setYoutube();
      }, document.body.scrollWidth < 992 ? 10000 : 15000);  
    })

    window.addEventListener('mousemove', setYoutube);
    window.addEventListener('scroll', setYoutube);
  },
  setIcons: () => {
    const section = document.querySelector('[data-icons-swiper] .swiper');
    const next = document.querySelector('[data-icons-swiper] .swiper-button-next');
    const prev = document.querySelector('[data-icons-swiper] .swiper-button-prev');

    const icons = new Swiper(section, {
      slidesPerView: 3,
      watchOverflow: true,
      preloadImages: false,
      speed: 1000,
      spaceBetween: 10,
      lazy: {
        checkInView: true,
        loadOnTransitionStart: true,
      },
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        991: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });
  },
  setInstagramSwiper: function () {
    const instagramSwiper = new Swiper('[data-instagram-swiper]', {
      slidesPerView: 2.3,
      slidesPerGroup: 2,
      spaceBetween: 10,
      preloadImages: false,
      watchOverflow: true,
      centerInsufficientSlides: true,
      speed: 1000,
      lazy: {
        checkInView: true,
        loadPrevNext: true,
        loadOnTransitionStart: true,
        loadPrevNextAmount: 1,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 20,
        },
      },
      pagination: {
        el: '[data-instagram-swiper] .swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  },
  handleYoutubeVideo: function (videoId, divId, youtubePlayer) {
    const player = new YT.Player(divId, {
      videoId: videoId, // YouTube Video ID
      width: 560,               // Player width (in px)
      height: 316,              // Player height (in px)
      playerVars: {
        autoplay: 1,        // Auto-play the video on load
        controls: 0,        // Show pause/play buttons in player
        showinfo: 0,        // Hide the video title
        modestbranding: 1,  // Hide the Youtube Logo
        loop: 1,            // Run the video in a loop
        fs: 0,              // Hide the full screen button
        cc_load_policy: 0, // Hide closed captions
        iv_load_policy: 3,  // Hide the Video Annotations
        autohide: 1,         // Hide video controls when playing
        mute: 1,
        disablekb: 1,
        rel: 0,
        playlist: videoId,
        playsinline: 1
      },
      events: {
        onReady: function (e) {
          e.target.mute();
          e.target.playVideo();

          youtubePlayer.querySelector('iframe').classList.add("-active"); //remove scale from video
        }
      }
    });
  },

  handleRDStationCode: function() {
    // Lida com a div de primeira compra do RD station;
    const element = document.querySelector('[data-rd-station]')

    if (element) {
      const observer = new IntersectionObserver((entries) => {
        // isIntersecting retorna True quando o elemento entra em página
        if (entries[0].isIntersecting) {

          if (!window.RDStationForms) {
            const scriptEl = document.createElement('script');
            scriptEl.setAttribute('src','https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js');
            document.body.appendChild(scriptEl);
          }

          if (element.getAttribute('data-processed') === 'false') {
            const interval = setInterval(() => {
              if (window.RDStationForms) {
                const rdInstance = new RDStationForms('primeira-compra-3fe4f29a9333eafbb58a', 'UA-167958286-1').createForm();
                clearInterval(interval)
                element.setAttribute('data-processed', 'true')
              }
            },100)
            element.setAttribute('data-processed', 'loading')
          }
        }
      }, { threshold: 0.1 });
      
      observer.observe(element);
    }

  },
  init: function () {
    const _this = this;
    AOS.init({ once: true });

    _this.setFullbanner();
    // _this.handleRDStationCode();
    _this.setInstagramSwiper();
    _this.setIcons();

    this.setYoutubeCode();
    handleLoadVideos()

    setCarousel();
    updatePriceBlock();
    InfinityScroll.init();
  },
};

window.addEventListener('DOMContentLoaded', () => {
  Home.init()
})
