import Swiper, { Navigation, Pagination, Lazy, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Lazy, Autoplay]);

export default function setCarousel() {
  const productsCarousel = document.querySelectorAll('[data-products-carousel]');

  productsCarousel.forEach((section) => {
    const carousel = section.querySelector('.swiper');
    const pagination = section.querySelector('.swiper-pagination');
    const next = section.querySelector('.swiper-button-next');
    const prev = section.querySelector('.swiper-button-prev');

    if (carousel) {
      new Swiper(carousel, {
        autoHeight: false,
        slidesPerView: 2,
        spaceBetween: 6,
        watchOverflow: true,
        centerInsufficientSlides: true,
        speed: 1000,
        //lazy load
        preloadImages: false,
        lazy: {
          checkInView: true,
          loadPrevNext: true,
          loadOnTransitionStart: true,
          loadPrevNextAmount: 1,
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        pagination: {
          el: pagination,
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
            navigation: {
              nextEl: next,
              prevEl: prev,
            },

            pagination: false,
          },
        },
      });
    }
  });
}
